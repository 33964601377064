/* Core */
@import "bootstrap";
/* FONTS */
@import "font-awesome";


/*
 *From here you can put your custom CSS 
 */
body { 
  padding-top: 70px;
}

#sidebar { 
  padding-top: 70px;
}

.centered {
    text-align: center;
}
